<template>
    <v-layout column class="header_sticky" >
            <div class="layoutHeader">
                <h2 class="headerTitle">{{ title }}</h2>
                <ul class="snb" v-if="path.includes('reading')">
                    <li v-for="(item, i) in headerMenuList" :key="i" :class="{'on': $route.name === item.to}">
                        <p class="num">0{{i+1}}</p>
                        <div class="txt">
                            <p class="tit">{{item.name}}</p>
                            <p class="txt">{{item.desc}}</p>
                        </div>
                    </li>
                </ul>
                <!-- <a v-if="$route.name === 'userManage'" class="btn solid" v-ripple @click="checkActivatedUserCnt()">{{ headerButton.name }}</a> -->
                <!-- <router-link v-if="isAdmin && $route.name === 'userManage'" :to="{name:headerButton.to}" class="btn solid" v-ripple>{{ headerButton.name }}</router-link> -->
            </div>
    </v-layout>
</template>

<script>
export default {
    components: {
        
    },
    props: {
        onClick: {
            default: ()=>{ }
        },
        showHeader: {
            default: true
        },
        title: {
            default: ''
        },
        path: {
            default: ''
        }
    },
    data() {
        return {
            headerMenu: [],
            compoName:null,
            openModal:false,
            openModalText:'',
            showLang:false,
            scroll: true,
            closeOnContentClick: false,
            search: false,
            menu: false,
            openPopup: false,
            pineDx: process.env.VUE_APP_PINE_DX
        };
    },
    watch: {
        
    },
    created() {
    },
    mounted() {
        this.headerMenuList.forEach(item => {
            if(item.to === 'step1') item.desc = this.dynamicDesc(this.pineDx);
        });
    },
    destroyed() {
    },
    computed:{
        headerMenuList() {
            return [
                { name: this.$t("header.user.menu1.step1.subTitle"), desc: this.$t("header.user.menu1.step1.subText"), to: 'step1' },
                { name: this.$t("header.user.menu1.step2.subTitle"), desc: this.$t("header.user.menu1.step2.subText"), to: 'step2' },
            ];
        }
    },
    methods: {
        dynamicDesc(pineDx) {
            if (pineDx === 'C') return '척추 X-ray 등록';
            else if (pineDx === 'B') return '수부 X-ray 등록';
            else return this.$t("header.user.menu1.step1.subText");
        },
        updateMenuList() {
            this.headerMenuList = [
                { name: this.$t("header.user.menu1.step1.subTitle"), desc: this.$t("header.user.menu1.step1.subText"), to: 'step1' },
                { name: this.$t("header.user.menu1.step2.subTitle"), desc: this.$t("header.user.menu1.step2.subText"), to: 'step2' },
            ];
        },
    },
}; 
</script>
<style lang="scss" scoped>
@import "../sass/app.scss";
body::-webkit-scrollbar{
    display: none;
}

.header_sticky{
    position: relative;
    width: 100%;
    top: 0px;
    z-index: 22;
}

.btn.solid{
  transition: box-shadow 0.5s, opacity 0.5s;
}
.btn.solid:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
  opacity: 0.85;
}

</style>